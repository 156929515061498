import React, { useContext, useState, useEffect } from "react";
import {
  Input,
  Radio,
  RadioGroup,
  Checkbox,
  Modal,
  Button,
  Icon,
} from "@salesforce/design-system-react";
import { useTranslation } from "react-i18next";
import { PCRContext } from "context/PCRContext";
import PCRComboBox from "../../../../common/PCRComboBox";
import {
  oxygenationDevices,
  VALIDATION_TYPES,
  yesOrNoRadioOptions,
  ventilationDevices,
  airwayOptions,
  airwayACPOptions,
  locationThoracostomyOptions,
  cricothyrotomyOptions,
} from "../../../../../data/constants";
import "../../../../../css/airwayAndBreathing.css";
import AdvancedAirway from "./AdvancedAirway";
import moment from "moment";
import PCRTimePicker from "../../../../common/PCRTimePicker";

const PCRAirwayAndBreathing = ({ pcr }) => {
  const { t } = useTranslation();
  const { pcrData, setPCRData, formValidationConfig,treatmentErrors, setTreatmentErrors } = useContext(PCRContext);
  const { treatment } = pcrData || {};
  const airwayAndBreathing = treatment?.airwayAndBreathing || [];

  const [selectedOxygenationDevice, setSelectedOxygenationDevice] =
    useState("");
  const [oxygenDeliveredLMin, setOxygenDeliveredLMin] = useState("");
  const [oxygenationDeviceTime, setoxygenationDeviceTime] = useState("");
  const [isVentilationSelected, setIsVentilationSelected] = useState("");
  const [selectedVentilationDevice, setSelectedVentilationDevice] =
    useState("");
  const [ventilationFlowRate, setVentilationFlowRate] = useState("");
  const [selectedAirwayOptions, setSelectedAirwayOptions] =
    useState(airwayOptions);
  const [cpapFlowRate, setCpapFlowRate] = useState("");
  const [magillInitials, setMagillInitials] = useState("");
  const [magillID, setMagillID] = useState("");
  const [selectedAirwayACPOptions, setSelectedAirwayACPOptions] =
    useState(airwayACPOptions);
  const [peepValue, setPeepValue] = useState("");
  const [needleThoracostomyLocation, setNeedleThoracostomyLocation] =
    useState("");
  const [needleAttempts, setNeedleAttempts] = useState("");
  const [cricothyrotomyType, setCricothyrotomyType] = useState("");

  // State to manage the array of AdvancedAirway components
  const [airwayItems, setAirwayItems] = useState([{ id: 0, data: {} }]); // Track rows with their data
  const [selectedAirways, setSelectedAirways] = useState([]); // Manage selected airways
  const [selectedRowForDeletion, setSelectedRowForDeletion] = useState(null); // Row selected for deletion
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const updatePCRContext = (inputObj,parentObj = "treatment") => {
    const field = formValidationConfig.find(
      (f) => f.name === Object.keys(inputObj)[0]
    );
    let errorState = {...treatmentErrors};

    Object.entries(inputObj).forEach(([key, value]) => {
      field?.validations.forEach((type) => {
        if (field?.shouldDisplayError && !value) {
          switch (type) {
            case VALIDATION_TYPES.VALUE_REQUIRED:
              errorState[`${field.name}Error`] = t("inputIsRequiredError");
              break;
            default:
              break;
          }
        }
        if (field?.shouldDisplayError && value) {
          errorState[`${field.name}Error`] = "";
        }
      });
    });

    if(parentObj == 'treatment'){
      setPCRData({
        ...pcrData,
        treatment: {
          ...treatment,
         inputObj,
        },
      });
    }
    else{
      setPCRData({
        ...pcrData,
        treatment: {
          ...treatment,
          airwayAndBreathing: { ...airwayAndBreathing, ...inputObj },
        }
      });
    }
    setTreatmentErrors(errorState);
   
  };

  // Handle oxygenation device change
  const handleOxygenationDeviceChange = (selected) => {
    const oxygenationDeviceValue = selected.label || selected;
    setSelectedOxygenationDevice(oxygenationDeviceValue);
    updatePCRContext({ oxygenationDevice: oxygenationDeviceValue });
  };

  // Handle oxygen flowrate change
  const handleOxygenDeliveredLMin = (e) => {
    let error = {...treatmentErrors};
    const value = e.target.value;
    if (value > 0 && /^\d*$/.test(value)) {
      updatePCRContext({ oxygenDeliveredLMin: value });
      setOxygenDeliveredLMin(value);
      error['oxygenDeliveredLMinError'] = "";
    }
    if(value >= 0 && !( /^\d*$/.test(value))){
      error['oxygenDeliveredLMinError'] = t('invalidFormat');
    }

    setTreatmentErrors(error);
  };

  // Handle oxygen duration change
  const handleOxygenDeviceTime = (timestamp) => {
    let formatteTime = timestamp;

    if (timestamp && timestamp.length > 5) {
      formatteTime = moment(timestamp).utc().format("HH:mm");
    }

    setoxygenationDeviceTime(timestamp);
    updatePCRContext({ oxygenationDeviceTime: timestamp });
  };

  // Handle ventilation change (Yes/No)
  const handleVentilationChange = (e) => {
    const selectedValue = e.target.value;
    setIsVentilationSelected(selectedValue);
    updatePCRContext({ ventilation: selectedValue });

    if (selectedValue === "No") {
      setSelectedVentilationDevice("Select");
      setVentilationFlowRate("");
    }
  };

  // Handle ventilation device selection
  const handleVentilationDeviceChange = (selected) => {
    const ventilationDeviceValue = selected.label || selected;
    setSelectedVentilationDevice(ventilationDeviceValue);
    updatePCRContext({ ventilationDevice: ventilationDeviceValue });
  };

  // Handle ventilation flow rate change
  const handleVentilationFlowRateChange = (e) => {
    const value = e.target.value;
    if (value >= 0 && /^\d*$/.test(value)) {
      setVentilationFlowRate(value);
      updatePCRContext({ ventilationDeliveredLMin : value });
    }
  };

  // Handle airway option selection
  const handleAirwaySelection = (id, isChecked) => {
    const updatedAirwayOptions = selectedAirwayOptions.map((option) =>
      option.id === id ? { ...option, value: isChecked } : option
    );
    setSelectedAirwayOptions(updatedAirwayOptions);
    updatePCRContext({ airwayOptions: updatedAirwayOptions });
  };

  // Handle CPAP flow rate change
  const handleCpapFlowRateChange = (e) => {
    const value = e.target.value;
    if (value > 0 && /^\d*$/.test(value)) {
      setCpapFlowRate(value);
      //updatePCRContext({ cpapFlowRate: value });
    }
  };

  // Handle Magill initials change (validation for alphabetical characters)
  const handleMagillInitialsChange = (e) => {
    const value = e.target.value;
    if (/^[A-Za-zÀ-ÿ]*$/.test(value)) {
      setMagillInitials(value);
      updatePCRContext({ magillInitials: value });
    }
  };

  // Handle Magill ID change
  const handleMagillIDChange = (e) => {
    setMagillID(e.target.value);
    updatePCRContext({ magillID: e.target.value });
  };

  // Handle ACP Airway option selection
  const handleAirwayACPSelection = (id, isChecked) => {
    const updatedOptions = selectedAirwayACPOptions.map((option) =>
      option.id === id ? { ...option, value: isChecked } : option
    );
    setSelectedAirwayACPOptions(updatedOptions);
    updatePCRContext({ airwayACPOptions: updatedOptions });
  };

  // Handle PEEP value change
  const handlePeepValueChange = (e) => {
    const value = e.target.value;
    if (value >= 0 && /^\d*$/.test(value)) {
      setPeepValue(value);
      updatePCRContext({ peepValue: value });
    }
  };

  // Handle Needle Thoracostomy location change
  const handleNeedleLocationChange = (selected) => {
    const locationValue = selected.label || selected;
    setNeedleThoracostomyLocation(locationValue);
    updatePCRContext({ needleThoracostomyLocation: locationValue });
  };

  // Handle Needle Thoracostomy attempts change
  const handleNeedleAttemptsChange = (e) => {
    const value = e.target.value;
    if (value >= 0 && /^\d*$/.test(value)) {
      setNeedleAttempts(value);
      updatePCRContext({ needleAttempts: value });
    }
  };

  // Handle Cricothyrotomy type change
  const handleCricothyrotomyTypeChange = (selected) => {
    const typeValue = selected.label || selected;
    setCricothyrotomyType(typeValue);
    updatePCRContext({ cricothyrotomyType: typeValue });
  };

  useEffect(() => {
    const airwayData = pcrData?.treatment?.airwayAndBreathing?.[0];

    if (!airwayData) return;

    setSelectedOxygenationDevice(airwayData.oxygenationDevice || "Select");
    setOxygenDeliveredLMin(airwayData.oxygenDeliveredLMin || "");
    setoxygenationDeviceTime(airwayData.oxygenationDeviceTime || "");
    setIsVentilationSelected(airwayData.ventilation || "No");
    setSelectedVentilationDevice(airwayData.ventilationDevice || "Select");
    setVentilationFlowRate(airwayData.ventilationFlowRate || "");
    setSelectedAirwayOptions(airwayData.airwayOptions || airwayOptions);
    setCpapFlowRate(airwayData.cpapFlowRate || "");
    setMagillInitials(airwayData.magillInitials || "");
    setMagillID(airwayData.magillID || "");
    setSelectedAirwayACPOptions(
      airwayData.airwayACPOptions || airwayACPOptions
    );
    setPeepValue(airwayData.peepValue || "");
    setNeedleThoracostomyLocation(
      airwayData.needleThoracostomyLocation || "Select"
    );
    setNeedleAttempts(airwayData.needleAttempts || "");
    setCricothyrotomyType(airwayData.cricothyrotomyType || "Select");
  }, [pcrData]);

  useEffect(() => {
    if (airwayAndBreathing.length) {
      setAirwayItems(airwayAndBreathing);
    }
  }, [airwayAndBreathing]);

  // Function to handle airway selection via checkbox
  const handleAirwaySelect = (index, checked) => {
    if (checked) {
      setSelectedAirways((prev) => [...prev, index]);
    } else {
      setSelectedAirways((prev) => prev.filter((i) => i !== index));
    }
  };

  // Function to add a new row
  const addAirwayItem = () => {
    const newId =
      airwayItems.length > 0 ? airwayItems[airwayItems.length - 1].id + 1 : 0;
    setAirwayItems([...airwayItems, { id: newId, data: {} }]);
  };

  // Function to handle row data changes
  const handleRowDataChange = (index, newData) => {
    const updatedItems = [...airwayItems];
    updatedItems[index].data = newData;
    setAirwayItems(updatedItems);
  };

   // Function to check if a row contains any data (returns true if it has data)
   const isRowEmpty = (rowData) => {
    return Object.entries(rowData)
      .filter(([key]) => key !== 'id' && key !== 'data') // Skip 'id' and 'data' keys
      .every(([_, value]) => value === "" || value === null || value === undefined);
  };
  

  // Function to handle the delete button
  const handleDeleteClick = (index) => {
    const rowData = airwayItems[index];
  
    if (isRowEmpty(rowData)) {
      // setSelectedRowForDeletion(index);
      removeAirwayItem(index);
    }else{
      setSelectedRowForDeletion(index);
      setShowDeleteModal(true);
    }
   
  };

  // Function to remove a row
  const removeAirwayItem = (index) => {
    const updatedItems = airwayItems.filter((_, i) => i !== index);
    setAirwayItems(updatedItems);
    setSelectedRowForDeletion(null);
    setShowDeleteModal(false);
  };

  // Function to confirm row deletion in the modal
  const confirmDeletion = () => {
    removeAirwayItem(selectedRowForDeletion);
  };

  return (
    <>
      <div className="pcr-ab-field-container">
        {/* Oxygenation Device */} {}
        <div>
          <label
            className="slds-form-element__label"
            htmlFor="oxygenationDevices"
          >
            {t("Oxygenation device")}
            <PCRComboBox
              id="oxygenationDevice-combo-box"
              label=""
              options={oxygenationDevices}
              value={selectedOxygenationDevice}
              placeholder={t("Select")}
              onChange={handleOxygenationDeviceChange}
              onSelect={(val) =>
                handleOxygenationDeviceChange(val.oxygenationDevice)
              }
              selection={
                selectedOxygenationDevice
                  ? [
                      {
                        id: selectedOxygenationDevice,
                        label: selectedOxygenationDevice,
                      },
                    ]
                  : []
              }
              name="oxygenationDevice"
            />
          </label>
        </div>

        {/* Oxygen Flow Rate */}
        <div className="oxygen-fields">
          <Input
            id="oxygenDeliveredLMin"
            placeholder="l/m"
            value={oxygenDeliveredLMin}
            onChange={handleOxygenDeliveredLMin}
            type="number"
            min="1"
            step="1"
            errorText={
              treatmentErrors?.oxygenDeliveredLMinError && (
                <p style={{color: "red"}}>{treatmentErrors?.oxygenDeliveredLMinError}</p>
              )
            }
            name="oxygenDeliveredLMin"
          />
        </div>

        {/* Oxygen Duration */}
        <div className="oxygen-fields">
        
          <PCRTimePicker
            assistiveText={{ label: "oxygenationDeviceTime" }}
            id="oxygenationDeviceTime"
            className="bor-rad m10 pad-1 w120p"
            placeholder="HH:MM"
            value={oxygenationDeviceTime}
            name="oxygenationDeviceTime"
            onChange={(val) => {
              handleOxygenDeviceTime(val.oxygenationDeviceTime);
            }}
            onError={(val) => {
              let temp = {...treatmentErrors}
              temp['oxygenationDeviceTimeError'] = val;
              setTreatmentErrors(temp);
            }}
          />
        </div>

        {/* Ventilation */}
        <div className="radio-alignment ventilation-radio-field">
          <RadioGroup
            labels={{ label: t("Ventilation") }}
            name="ventilation-radio-group"
            className="disp-align-end"
            onChange={handleVentilationChange}
          >
            {yesOrNoRadioOptions.map((ele) => (
              <Radio
                key={ele.value}
                id={`ventilation-radio-${ele.value}`}
                labels={{ label: ele.label }}
                value={ele.value}
                checked={isVentilationSelected === ele.value}
                variant="base"
              />
            ))}
          </RadioGroup>
        </div>

        {isVentilationSelected === "Yes" && (
          <>
            {/* Ventilation Device */}
            <div className="df h-4half-r ventilation-fields">
              <div className="pcr-ab-combo-box">
                <label
                  className="slds-form-element__label"
                  htmlFor="ventilationDevice"
                >
                  {t("Ventilation Device")}
                  <PCRComboBox
                    id="ventilationDevice-combo-box"
                    label=""
                    options={ventilationDevices}
                    value={selectedVentilationDevice}
                    placeholder={t("Select")}
                    onChange={handleVentilationDeviceChange}
                    onSelect={(val) =>
                      handleVentilationDeviceChange(val.ventilationDevice)
                    }
                    selection={
                      selectedVentilationDevice
                        ? [
                            {
                              id: selectedVentilationDevice,
                              label: selectedVentilationDevice,
                            },
                          ]
                        : []
                    }
                    name="ventilationDevice"
                  />
                </label>
              </div>

              {/* Ventilation Flow Rate */}
              <div className="oxygen-fields">
                <Input
                  id="ventilationDeliveredLMin"
                  placeholder="l/m"
                  value={ventilationFlowRate}
                  onChange={handleVentilationFlowRateChange}
                  type="number"
                  min="1"
                  step="1"
                  errorText={
                    ventilationFlowRate && ventilationFlowRate <= 0
                      ? t("invalidFormat")
                      : ""
                  }
                  name="ventilationDeliveredLMin"
                />
              </div>
            </div>
          </>
        )}
      </div>

      <div className="m-t-b">
        <div className="df h-4half-r">
          {/* Airway */}
          <label className="slds-form-element__label" htmlFor="airwayOptions">
            {t("Airway")}
            <div className="align-down">
              {selectedAirwayOptions.map((option) => (
                <div
                  key={option.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "0.5rem",
                  }}
                >
                  <Checkbox
                    assistiveText={{ label: "Default" }}
                    id={`checkbox-airway-${option.id}`}
                    labels={{ label: option.label }}
                    checked={option.value}
                    onChange={(e) =>
                      handleAirwaySelection(option.id, e.target.checked)
                    }
                  />
                  {option.id === "cpap" && option.value && (
                    <Input
                      id="cpap-FlowRate"
                      placeholder="l/min"
                      value={cpapFlowRate}
                      onChange={handleCpapFlowRateChange}
                      type="number"
                      min="1"
                      step="1"
                      errorText={
                        cpapFlowRate && cpapFlowRate <= 0
                          ?  t("invalidFormat")
                          : ""
                      }
                      style={{ marginLeft: "1rem", width: "100px" }}
                    />
                  )}
                  {option.id === "magill" && option.value && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "0.5rem",
                        }}
                      >
                        {/* Magill Initials */}
                        <Input
                          id="magillInitials"
                          placeholder={t("Initials")}
                          value={magillInitials}
                          onChange={handleMagillInitialsChange}
                          errorText={
                            magillInitials &&
                            !/^[A-Za-zÀ-ÿ]*$/.test(magillInitials)
                              ? t("invalidFormat")
                              : !magillInitials ? t("inputIsRequiredError") : ""
                          }
                          required={option.id === "magill"}
                        />
                        {/* Magill ID */}
                        <Input
                          id="magillID"
                          placeholder={t("ID#")}
                          value={magillID}
                          onChange={handleMagillIDChange}
                          required={option.id === "magill"}
                          errorText={
                            !magillID && t("inputIsRequiredError")
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </label>

          {/* Airway: ACP */}
          <label
            className="slds-form-element__label"
            htmlFor="airwayACPOptions"
          >
            {t("Airway: ACP")}
            <div className="align-down">
              {selectedAirwayACPOptions.map((option) => (
                <div
                  key={option.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "0.5rem",
                  }}
                >
                  <Checkbox
                    assistiveText={{ label: option.label }}
                    id={`checkbox-airway-acp-${option.id}`}
                    labels={{ label: option.label }}
                    checked={option.value}
                    onChange={(e) =>
                      handleAirwayACPSelection(option.id, e.target.checked)
                    }
                  />
                  {/* Conditional Rendering for PEEP */}
                  {option.id === "peep" && option.value && (
                    <Input
                      id="peepValue"
                      placeholder="cm of H₂O"
                      value={peepValue}
                      onChange={handlePeepValueChange}
                      type="number"
                      min="0"
                      step="1"
                      errorText={
                        peepValue && peepValue < 0
                          ?  t("invalidFormat")
                          : ""
                      }
                    />
                  )}
                  {/* Conditional Rendering for Needle Thoracostomy */}
                  {option.id === "needleThoracostomy" && option.value && (
                    <>
                      <div className="df h-4half-r">
                        <div className="pcr-ab-combo-box">
                          <label
                            className="slds-form-element__label"
                            htmlFor="needleLocation"
                          >
                            {t("Location")}
                            <PCRComboBox
                              id="needleLocation-combo-box"
                              label=""
                              options={locationThoracostomyOptions}
                              value={needleThoracostomyLocation}
                              placeholder={t("Select")}
                              onChange={handleNeedleLocationChange}
                              onSelect={(val) =>
                                handleNeedleLocationChange(
                                  val.needleThoracostomyLocation
                                )
                              }
                              selection={
                                needleThoracostomyLocation
                                  ? [
                                      {
                                        id: needleThoracostomyLocation,
                                        label: needleThoracostomyLocation,
                                      },
                                    ]
                                  : []
                              }
                              name="needleThoracostomyLocation"
                            />
                          </label>
                        </div>
                        <div className="oxygen-fields">
                          <Input
                            id="needleAttempts"
                            placeholder="# of attempts"
                            value={needleAttempts}
                            onChange={handleNeedleAttemptsChange}
                            type="number"
                            min="0"
                            step="1"
                            errorText={
                              needleAttempts && needleAttempts < 0
                                ? t("invalidFormat")
                                : ""
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {/* Conditional Rendering for Cricothyrotomy */}
                  {option.id === "cricothyrotomy" && option.value && (
                    <div className="df h-4half-r">
                      <div className="pcr-ab-combo-box">
                        <label
                          className="slds-form-element__label"
                          htmlFor="cricothyrotomyType"
                        >
                          {t("Cricothyrotomy Type")}
                          <PCRComboBox
                            id="cricothyrotomyType-combo-box"
                            label=""
                            options={cricothyrotomyOptions}
                            value={cricothyrotomyType}
                            placeholder={t("Select")}
                            onChange={handleCricothyrotomyTypeChange}
                            onSelect={(val) =>
                              handleCricothyrotomyTypeChange(
                                val.cricothyrotomyType
                              )
                            }
                            selection={
                              cricothyrotomyType
                                ? [
                                    {
                                      id: cricothyrotomyType,
                                      label: cricothyrotomyType,
                                    },
                                  ]
                                : []
                            }
                            name="cricothyrotomyType"
                          />
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </label>
        </div>
      </div>

      {/* Advanced Airway section */}
      <div className="slds-card">
        <div className="main-pcr-vitals">
          <section className="slds-card_header">
            <div
              className="df vitals-heading-section"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2 className="slds-card__header-title vital-signs-header">
                Advanced Airway
              </h2>
            </div>
          </section>
        </div>

        <div className="slds-card__body">
          {airwayItems.map((item, index) => (
            <div
              key={item.id}
              className="grid_row_airway"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "30px",
              }}
            >
              <div style={{ flex: 1 }}>
                <AdvancedAirway
                  key={item.id}
                  index={index}
                  isSelected={selectedAirways.includes(item.id)}
                  onAirwaySelect={handleAirwaySelect}
                  updatePCRContext={updatePCRContext}
                  airwayItems={airwayItems}
                  setAirwayItems={(val) => {
                     setAirwayItems(val)
                  }}
                  t={t}

                />
              </div>
              {/* Minus Button */}
              {airwayItems.length >= 1 && (
                <>
                  {index === 0 ? (
                    <Button
                      iconCategory="utility"
                      iconName="new"
                      variant="icon"
                      className="slds-icon-text-success"
                      onClick={addAirwayItem}
                      iconSize="large"
                      style={{ marginLeft: "15px", marginTop: "-0.5rem" }}
                    />
                  ) : (
                    <Button
                      iconCategory="utility"
                      iconName="ban"
                      variant="icon"
                      className="slds-icon-text-error"
                      onClick={() => handleDeleteClick(index)}
                      iconSize="large"
                      style={{ marginLeft: "15px", marginTop: "25px" }}
                    />
                  )}
                </>
              )}
            </div>
          ))}
        </div>

        {/* Warning Modal */}
        {showDeleteModal && (
          <Modal
            footer={[
              <Button label="Confirm" onClick={confirmDeletion} />,
              <Button
                label="Cancel"
                onClick={() => setShowDeleteModal(false)}
              />,
            ]}
            isOpen={showDeleteModal}
            onRequestClose={() => setShowDeleteModal(false)}
            title={
              <span>
                <Icon
                  assistiveText={{ label: "warning" }}
                  category="utility"
                  name="warning"
                  size="medium"
                  style={{ fill: "#fe9339" }}
                />
                <span>Warning: Data Deletion</span>
              </span>
            }
          >
            <div className="slds-m-around_medium">
              <p>
               You are about to remove a row that contains data. Are you sure you want to proceed?
              </p>
            </div>
          </Modal>
        )}
      </div>
    </>
);
};

export default PCRAirwayAndBreathing;
