import React, { useContext, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { RadioGroup, Radio, Checkbox, Input, Icon, Button, Textarea, Tooltip } from "@salesforce/design-system-react";
import { PCRContext } from "../../../../context/PCRContext";
import PCRComboBox from "../../../common/PCRComboBox";
import {
    VALIDATION_TYPES,
    yesOrNoRadioOptions,
    involvedVehiclesOptions,
    protectiveDevicesOptions,
    impactOptions,
    injuryLocationOptions,
    assaultInjuryOptions,
    mechanismOptions,
    burnOptions,
    splintingOptions,
    bleedingOptions
} from "../../../../data/constants";
import "../../../../css/traumaAssessment.css";
import PDFViewer from "../../../PDFViewer";
import PCRTextInput from "../../../../components/common/PCRTextInput";

export const PCRTraumaAssessment = ({ pcr, id, handleCancelConfirmation }) => {
    const { pcrData, setPCRData, formValidationConfig } = useContext(PCRContext);
    const { assessment } = pcrData;
    const { t } = useTranslation();

    const [isTraumaPatient, setIsTraumaPatient] = useState(assessment?.traumaPatient || "");
    const [selectedVehicles, setSelectedVehicles] = useState(involvedVehiclesOptions);
    const [carSpeed, setCarSpeed] = useState("");
    const [otherVehicle, setOtherVehicle] = useState("");
    const [selectedDevices, setSelectedDevices] = useState(protectiveDevicesOptions);
    const [ejectedDistance, setEjectedDistance] = useState("");
    const [selectedImpacts, setSelectedImpacts] = useState(impactOptions);
    const [selectedLocation, setSelectedLocation] = useState("");
    const [otherLocation, setOtherLocation] = useState("");
    const [selectedAssaultInjuries, setSelectedAssaultInjuries] = useState(assaultInjuryOptions);
    const [rangeDistance, setRangeDistance] = useState("");
    const [numWounds, setNumWounds] = useState("");
    const [selectedMechanism, setSelectedMechanism] = useState("");
    const [fallHeight, setFallHeight] = useState("");
    const [fallSurface, setFallSurface] = useState("");
    const [stairsSteps, setStairsSteps] = useState("");
    const [stairsSurface, setStairsSurface] = useState("");
    const [selectedBurn, setSelectedBurn] = useState("");
    const [otherBurnDetail, setOtherBurnDetail] = useState("");
    const [otherInjuryType, setOtherInjuryType] = useState("");
    const [additionalNotes, setAdditionalNotes] = useState("");
    const [assessmentId, setAssessmentId] = useState("");
    const [assessmentInitials, setAssessmentInitials] = useState("");
    const [selectedSplintingOptions, setSelectedSplintingOptions] = useState(splintingOptions);
    const [otherSplinting, setOtherSplinting] = useState("");
    const [selectedBleedingOptions, setSelectedBleedingOptions] = useState(bleedingOptions);
    const [tourniquetTime, setTourniquetTime] = useState("");
    const [otherBleeding, setOtherBleeding] = useState("");
    const [showCSpineClearanceSheet, setShowCSpineClearanceSheet] = useState(false);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const tooltipRef = useRef(null);
    const [localErrorStates, setLocalErrorStates] = useState({});

    // Effect to set initial values based on existing assessment data
    useEffect(() => {
        setIsTraumaPatient(assessment?.traumaPatient || "");

        if (assessment?.injuryLocation) {
            setSelectedLocation(assessment.injuryLocation);
            if (assessment.injuryLocation === "Other") {
                setOtherLocation(assessment.otherInjuryLocation || "");
            }
        }

        if (assessment?.burns) {
            setSelectedBurn(assessment.burns);
            if (["Liquid", "Chemicals", "Other"].includes(assessment.burns)) {
                setOtherBurnDetail(assessment.otherBurnDetail || "");
            }
        }
    }, [assessment]);

    // Effect to close the tooltip if the user clicks outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
                closeTooltip();
            }
        };

        if (isTooltipOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isTooltipOpen]);

    // Function to update the PCR context with validation
    const updatePCRContext = (inputObj) => {
        const field = formValidationConfig.find(
            (f) => f.name === Object.keys(inputObj)[0]
        );
        let errorState = {};
        Object.entries(inputObj).forEach(([key, value]) => {
            field?.validations.forEach((type) => {
                if (field?.shouldDisplayError && !value) {
                    switch (type) {
                        case VALIDATION_TYPES.VALUE_REQUIRED:
                            errorState[`${field.name}Error`] = t("inputIsRequiredError");
                            break;

                        case VALIDATION_TYPES.SELECTION_REQUIRED:
                            errorState[`${field.name}Error`] = t("inputSelectionRequiredError");
                            break;

                        default:
                            break;
                    }
                }
                if (field?.shouldDisplayError && value) {
                    errorState[`${field.name}Error`] = "";
                }
            });
        });

        setPCRData({
            ...pcrData,
            assessment: { ...assessment, ...inputObj },
            ...errorState,
        });
    };

    // Function to handle vehicle selection changes 
    const handleVehicleSelection = (id, val,from='') => {
        const updatedVehicles = selectedVehicles.map(vehicle => {
            if(vehicle.id === id){
                if(from === 'speed' && val >= 0){
                    vehicle.speed = val;
                }
                else if(from === 'other'){
                    vehicle.vehicleName = val;
                }
                else{
                    vehicle.value = val;
                }
            }
              
              return vehicle;
             
        }
        );
        setSelectedVehicles(updatedVehicles);
    };


    // Function to handle changes in car speed input
    const handleCarSpeedChange = (e) => {
        const value = e.target.value;
        setCarSpeed(value);
        // if(value < 0){
        //    setLocalErrorStates()
        // }
    };

    // Function to handle changes in other vehicle input
    const handleOtherVehicleChange = (e) => {
        setOtherVehicle(e.target.value);
    };

    // Function to handle protective device selection changes
    const handleDeviceSelection = (id, isChecked) => {
        const updatedDevices = selectedDevices.map(device =>
            device.id === id ? { ...device, value: isChecked } : device
        );
        setSelectedDevices(updatedDevices);
    };

    // Function to handle changes in ejected distance input
    const handleEjectedDistanceChange = (e) => {
        const value = e.target.value;
        if(e?.target?.value >= 0){
            setEjectedDistance(value);
        }
    };

    // Function to handle impact selection changes
    const handleImpactSelection = (id, isChecked) => {
        const updatedImpacts = selectedImpacts.map(impact =>
            impact.id === id ? { ...impact, value: isChecked } : impact
        );
        setSelectedImpacts(updatedImpacts);
    };

    // Function to handle location selection changes
    const handleLocationChange = (selected) => {
        if (selected !== "Other") {
            setOtherLocation("");
            updatePCRContext({ otherInjuryLocation: "" });
        }
        setSelectedLocation(selected);
        updatePCRContext({ injuryLocation: selected });
    };

    // Function to handle changes in other location input
    const handleOtherLocationChange = (e) => {
        setOtherLocation(e.target.value);
        updatePCRContext({ otherInjuryLocation: e.target.value });
    };

    // Function to handle assault injury selection changes
    const handleAssaultInjurySelection = (id, isChecked) => {
        const updatedAssaultInjuries = selectedAssaultInjuries.map(injury =>
            injury.id === id ? { ...injury, value: isChecked } : injury
        );
        setSelectedAssaultInjuries(updatedAssaultInjuries);
    };

    // Function to handle changes in range distance input
    const handleRangeDistanceChange = (e) => {
        const value = e.target.value;
        if(e?.target?.value >= 0){
         setRangeDistance(value);
        }   
    };

    // Function to handle changes in number of wounds input
    const handleNumWoundsChange = (e) => {
        const value = e.target.value;
        if(e?.target.value >= 0){
            setNumWounds(value);
        }

    };

    // Function to handle mechanism selection changes
    const handleMechanismChange = (selected) => {
        const mechanismValue = selected.label || selected;
        setSelectedMechanism(mechanismValue);
        updatePCRContext({ mechanism: mechanismValue });
    };

    // Function to handle changes in fall height input
    const handleFallHeightChange = (e) => {
        const value = e.target.value;
        setFallHeight(value);

    };

    // Function to handle changes in fall surface input
    const handleFallSurfaceChange = (e) => {
        setFallSurface(e.target.value);
    };

    // Function to handle changes in stairs steps input
    const handleStairsStepsChange = (e) => {
        const value = e.target.value;
        setStairsSteps(value);
    };

    // Function to handle changes in stairs surface input
    const handleStairsSurfaceChange = (e) => {
        setStairsSurface(e.target.value);
    };

    // Function to handle burn type selection changes
    const handleBurnSelection = (selected) => {
        setSelectedBurn(selected);
        updatePCRContext({ burns: selected });
    };

    // Function to handle changes in other burn detail input
    const handleOtherBurnDetailChange = (e) => {
        setOtherBurnDetail(e.target.value);
        updatePCRContext({ otherBurnDetail: e.target.value });
    };

    // Function to handle splinting options selection changes
    const handleSplintingSelection = (id, isChecked) => {
        const updatedOptions = selectedSplintingOptions.map(option =>
            option.id === id ? { ...option, value: isChecked } : option
        );
        setSelectedSplintingOptions(updatedOptions);
    };

    // Function to handle changes in other splinting input
    const handleOtherSplintingChange = (e) => {
        setOtherSplinting(e.target.value);
        updatePCRContext({ otherSplinting: e.target.value });
    };

    // Function to handle bleeding options selection changes
    const handleBleedingSelection = (id, isChecked) => {
        const updatedOptions = selectedBleedingOptions.map(option =>
            option.id === id ? { ...option, value: isChecked } : option
        );
        setSelectedBleedingOptions(updatedOptions);
    };

    // Function to handle changes in tourniquet time input
    const handleTourniquetTimeChange = (e) => {
        setTourniquetTime(e.target.value);
        updatePCRContext({ tourniquetTime: e.target.value });
    };

    // Function to handle changes in other bleeding input
    const handleOtherBleedingChange = (e) => {
        setOtherBleeding(e.target.value);
        updatePCRContext({ otherBleeding: e.target.value });
    };

    // Function to toggle the C-Spine Clearance Sheet visibility
    const handleSCC = () => setShowCSpineClearanceSheet(!showCSpineClearanceSheet);

    // Function to toggle the tooltip visibility
    const toggleTooltip = () => {
        setIsTooltipOpen(!isTooltipOpen);
    };

    // Function to close the tooltip
    const closeTooltip = () => {
        setIsTooltipOpen(false);
    };

    const allowedVehicleIds = ["car", "motorcycle", "truck", "atv", "bicycle", "transportTruck", "snowmobile"];

    // Function to validate the form and update the context with potential errors
    const handleValidate = () => {
        let canToggle = true;
        let errorState = {};

        const traumaAssessmentData = {
            traumaPatient: isTraumaPatient,
            carSpeed: selectedVehicles.find(vehicle => vehicle.id === "car")?.value ? carSpeed : null,
            otherVehicle: selectedVehicles.find(vehicle => vehicle.id === "other")?.value ? otherVehicle : null,
            ejectedDistance: selectedDevices.find(device => device.id === "ejected")?.value ? ejectedDistance : null,
            rangeDistance: selectedAssaultInjuries.find(injury => injury.id === "range")?.value ? rangeDistance : null,
            numWounds: selectedAssaultInjuries.find(injury => injury.id === "#wounds")?.value ? numWounds : null,
            fallHeight: selectedMechanism === "fall" ? fallHeight : null,
            fallSurface: selectedMechanism === "fall" ? fallSurface : null,
            stairsSteps: selectedMechanism === "downStairs" ? stairsSteps : null,
            stairsSurface: selectedMechanism === "downStairs" ? stairsSurface : null,
            burns: selectedBurn,
            otherBurnDetail: ["Liquid", "Chemicals", "Other"].includes(selectedBurn) ? otherBurnDetail : null,
            otherInjuryType: otherInjuryType || null,
        };

        if (traumaAssessmentData.carSpeed && traumaAssessmentData.carSpeed < 0) {
            errorState.carSpeedError = t("Speed must be >= 0 km/h");
            canToggle = false;
        }

        if (traumaAssessmentData.otherVehicle === "" && selectedVehicles.find(vehicle => vehicle.id === "other")?.value) {
            errorState.otherVehicleError = t("Please specify the vehicle");
            canToggle = false;
        }

        if (traumaAssessmentData.ejectedDistance && traumaAssessmentData.ejectedDistance < 0) {
            errorState.ejectedDistanceError = t("invalidFormat");
            canToggle = false;
        }

        if (selectedLocation === "Other" && !otherLocation) {
            errorState.otherInjuryLocationError = t("invalidFormat");
            canToggle = false;
        }

        if (traumaAssessmentData.rangeDistance && traumaAssessmentData.rangeDistance < 0) {
            errorState.rangeDistanceError = t("invalidFormat");
            canToggle = false;
        }

        if (traumaAssessmentData.numWounds && traumaAssessmentData.numWounds < 0) {
            errorState.numWoundsError = t("invalidFormat");
            canToggle = false;
        }

        if (traumaAssessmentData.fallHeight && traumaAssessmentData.fallHeight < 0) {
            errorState.fallHeightError = t("invalidFormat");
            canToggle = false;
        }

        if (traumaAssessmentData.stairsSteps && traumaAssessmentData.stairsSteps < 0) {
            errorState.stairsStepsError = t("invalidFormat");
            canToggle = false;
        }

        // if (!traumaAssessmentData.otherInjuryType) {
        //     errorState.otherInjuryTypeError = t("Please specify the type of injury");
        //     canToggle = false;
        // }

        if (additionalNotes && !assessmentId) {
            errorState.assessmentIdError = t("invalidFormat");
            canToggle = false;
        }

        if (additionalNotes && !assessmentInitials) {
            errorState.assessmentInitialsError = t("invalidFormat");
            canToggle = false;
        }

        if (selectedSplintingOptions.find(option => option.id === "other")?.value && !otherSplinting) {
            errorState.otherSplintingError = t("invalidFormat");
            canToggle = false;
        }

        if (selectedBleedingOptions.find(option => option.id === "other")?.value && !otherBleeding) {
            errorState.otherBleedingError = t("invalidFormat");
            canToggle = false;
        }

        if (tourniquetTime && !/^([01][0-9]|2[0-3]):[0-5][0-9]$/.test(tourniquetTime)) {
            errorState.tourniquetTimeError = t("invalidFormat");
            canToggle = false;
        }

        Object.entries(traumaAssessmentData).forEach(([key, value]) => {
            const field = formValidationConfig.find((f) => f.name === key);

            if (field?.shouldDisplayError && !value && !errorState[`${field.name}Error`]) {
                switch (field.validations[0]) {
                    case VALIDATION_TYPES.VALUE_REQUIRED:
                        errorState[`${field.name}Error`] = t("inputIsRequiredError");
                        break;

                    case VALIDATION_TYPES.SELECTION_REQUIRED:
                        errorState[`${field.name}Error`] = t("inputSelectionRequiredError");
                        break;

                    default:
                        break;
                }
                canToggle = false;
            }
        });

        setPCRData({
            ...pcrData,
            ...errorState,
        });

        // if (canToggle) handleCancelConfirmation(id);
    };

    const handleIntialsBlur = (e) => {
        if (!e.target.value) {
            setPCRData({ ...pcrData, ...{ assessmentInitialsError: t('inputIsRequiredError') } })
        }
        else if(!/^[A-Za-zÀ-ÿ]+$/.test(e?.target.value)){
            setPCRData({ ...pcrData, ...{ assessmentInitialsError: t('invalidFormat') } })
        }
        else
         {
            setPCRData({ ...pcrData, ...{ assessmentInitialsError: "" } })
        }
    }

    return (
        <>
            <PDFViewer
                showPDF={showCSpineClearanceSheet}
                onDone={handleSCC}
                fileName={`${process.env.PUBLIC_URL}/c-spine_clearance_sheet_E.pdf`}
            />
            <div className="df border-bottom-styles">
                <span className="details-icon call-details-icon">
                    <Icon
                        assistiveText={{ label: t("PCRTraumaAssessmentDetails") }}
                        category="utility"
                        name="call"
                        size="x-small"
                    />
                </span>
                <span className="section-header">{t("PCRTraumaAssessmentDetails")}</span>
            </div>

            {/* Is Trauma Patient RadioGroup */}
            <div className="m-t-b">
                <div className="df h-4half-r">
                    <div className="w-30 radio-alignment codeScene">
                        <RadioGroup
                            labels={{ label: t("isTraumaPatient") }}
                            required={pcrData?.identificationDetails?.noPatientsAssessedTransportedReason !== "No patient found"}
                            name="isTraumaPatient-radio-group"
                            className="disp-align-end"
                            onChange={(e) => {
                                const selectedValue = e.target.value;
                                setIsTraumaPatient(selectedValue);
                                updatePCRContext({ traumaPatient: selectedValue });
                            }}
                        >
                            {yesOrNoRadioOptions.map((ele) => (
                                <Radio
                                    key={ele.value}
                                    id={`traumaPatient-radio-${ele.value}`}
                                    labels={{ label: ele.label }}
                                    value={ele.value}
                                    checked={isTraumaPatient === ele.value}
                                    variant="base"
                                />
                            ))}
                        </RadioGroup>
                        <div className="slds-form-element__help selectionError">
                            {pcrData?.traumaPatientError && (
                                <>{pcrData?.traumaPatientError}</>
                            )}
                        </div>
                    </div>

                </div>

                {isTraumaPatient === "Yes" && (
                    <>
                        <div className="m-t-b">
                            <div className="df h-4half-r">
                                {/* Involved Vehicles */}
                                <div style={{ width: "250px" }}>
                                    <label className="slds-form-element__label" htmlFor="traumaVehicleTypes">
                                        Involved vehicles
                                        <div className="align-down">
                                            {selectedVehicles.map((vehicle) => (
                                                <div key={vehicle.id} style={{ display: "flex", alignItems: "center", marginBottom: "0.5rem" }}>
                                                    <Checkbox
                                                        assistiveText={{ label: "Default" }}
                                                        id={`checkbox-example-${vehicle.id}`}
                                                        labels={{ label: vehicle.label }}
                                                        checked={vehicle.value}
                                                        onChange={(e) => handleVehicleSelection(vehicle.id, e.target.checked)}
                                                    />
                                                    {allowedVehicleIds.includes(vehicle.id) && vehicle.value && (
                                                        <>
                                                            <div style={{ width: "100px" }}>
                                                                <Input
                                                                    id="car-speed"
                                                                    placeholder="km/h"
                                                                    value={vehicle.speed}
                                                                    type="number"
                                                                    name="carSpeed"
                                                                    onChange={(e) => handleVehicleSelection(vehicle.id, e.target.value,'speed')}
                                                                    style={{ marginLeft: "1rem" }}
                                                                    required={false}
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                    {vehicle.id === "other" && vehicle.value && (
                                                        <div style={{ width: "150px" }}>
                                                            <Input
                                                                id="other-vehicle"
                                                                placeholder="Please specify"
                                                                value={vehicle.vehicleName}
                                                                name="otherVehicle"
                                                                onChange={e =>handleVehicleSelection(vehicle.id, e.target.value,'other')}
                                                                onBlur={e => {
                                                                    if (!e.target.value) {
                                                                        setPCRData({ ...pcrData, ...{ otherVehicleError: t('Please specify the vehicle') } });
                                                                    }
                                                                    else {
                                                                        setPCRData({ ...pcrData, ...{ otherVehicleError: "" } });
                                                                    }

                                                                }}
                                                                errorText={pcrData.otherVehicleError && (
                                                                    <div className="slds-form-element__help selectionError df">
                                                                        {pcrData.otherVehicleError}
                                                                    </div>
                                                                )}
                                                                style={{ marginLeft: "1rem", width: "150px" }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </label>
                                </div>

                                {/* Restraints & Protective Devices */}
                                <div style={{ width: "250px" }}>
                                    <label className="slds-form-element__label" htmlFor="protectiveDeviceTypes">
                                        Restraints & protective devices
                                        <div className="align-down">
                                            {selectedDevices.map((device) => (
                                                <div key={device.id} style={{ display: "flex", alignItems: "center", marginBottom: "0.5rem" }}>
                                                    <Checkbox
                                                        assistiveText={{ label: "Default" }}
                                                        id={`checkbox-example-${device.id}`}
                                                        labels={{ label: device.label }}
                                                        checked={device.value}
                                                        onChange={(e) => handleDeviceSelection(device.id, e.target.checked)}
                                                    />
                                                    {device.id === "ejected" && device.value && (
                                                        <Input
                                                            id="ejected-distance"
                                                            // placeholder="m"
                                                            value={ejectedDistance}
                                                            type="number"
                                                            name="ejectedDistance"
                                                            onChange={e => handleEjectedDistanceChange(e)}
                                                            style={{ marginLeft: "1rem", width: "100px" }}
                                                        />
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </label>
                                </div>

                                {/* Point of Impact */}
                                <div style={{ width: "250px" }}>
                                    <label className="slds-form-element__label" htmlFor="impactTypes">
                                        Point of impact
                                        <div className="align-down">
                                            {selectedImpacts.map((impact) => (
                                                <div key={impact.id} style={{ display: "flex", alignItems: "center", marginBottom: "0.5rem" }}>
                                                    <Checkbox
                                                        assistiveText={{ label: "Default" }}
                                                        id={`checkbox-impact-${impact.id}`}
                                                        labels={{ label: impact.label }}
                                                        checked={impact.value}
                                                        onChange={(e) => handleImpactSelection(impact.id, e.target.checked)}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </label>
                                </div>

                                {/* Assault/Penetrating Injury */}
                                <label className="slds-form-element__label" htmlFor="assaultInjuryTypes">
                                    Assault/penetrating injury
                                    <div className="align-down">
                                        {selectedAssaultInjuries.map((injury) => (
                                            <div key={injury.id} style={{ display: "flex", alignItems: "center", marginBottom: "0.5rem" }}>
                                                <Checkbox
                                                    assistiveText={{ label: "Default" }}
                                                    id={`checkbox-assault-${injury.id}`}
                                                    labels={{ label: injury.label }}
                                                    checked={injury.value}
                                                    onChange={(e) => handleAssaultInjurySelection(injury.id, e.target.checked)}
                                                />
                                                {injury.id === "range" && injury.value && (
                                                    <Input
                                                        id="range-distance"
                                                        placeholder="m"
                                                        value={rangeDistance}
                                                        type="number"
                                                        name={"rangeDistance"}
                                                        onChange={handleRangeDistanceChange}
                                                        style={{ marginLeft: "1rem", width: "100px" }}
                                                    />
                                                )}
                                                {injury.id === "#wounds" && injury.value && (
                                                    <Input
                                                        id="num-wounds"
                                                        placeholder="#"
                                                        value={numWounds}
                                                        name="numWounds"
                                                        type="number"
                                                        onChange={handleNumWoundsChange}
                                                        style={{ marginLeft: "1rem", width: "100px" }}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </label>
                            </div>
                        </div>

                        <div className="m-t-b">
                            <div className="df h-4half-r">
                                {/* Patient Location at Time of Injury */}
                                <div style={{ width: "250px" }}>
                                    <label className="slds-form-element__label" htmlFor="injuryLocation">
                                        Patient location at time of injury
                                        <div className="width-field">
                                            <PCRComboBox
                                                id="injury-location-combo-box"
                                                label=""
                                                options={injuryLocationOptions}
                                                value={selectedLocation}
                                                placeholder={t("Select")}
                                                onChange={handleLocationChange}
                                                onSelect={(val) => handleLocationChange(val.injuryLocation)}
                                                required={true}
                                                selection={selectedLocation ? [{ id: selectedLocation, label: selectedLocation }] : []}
                                                name="injuryLocation"
                                                errorText={
                                                    pcrData?.injuryLocationError && (
                                                        <div className="slds-form-element__help selectionError df">
                                                            {pcrData?.injuryLocationError && <>{pcrData?.injuryLocationError}</>}
                                                        </div>
                                                    )
                                                }
                                            />
                                        </div>
                                        {selectedLocation === "Other" && (
                                            <div style={{ width: "100px" }}>
                                                <Input
                                                    id="other-injury-location"
                                                    placeholder={t("Please specify")}
                                                    value={otherLocation}
                                                    name={"otherInjuryLocation"}
                                                    onChange={handleOtherLocationChange}
                                                    className="full-width-burn"
                                                    required
                                                    onBlur={e => {
                                                        if (!e.target.value) {
                                                            setPCRData({ ...pcrData, ...{ otherInjuryLocationError: t('inputIsRequiredError') } })
                                                        }
                                                        else {
                                                            setPCRData({ ...pcrData, ...{ otherInjuryLocationError: "" } });
                                                        }
                                                    }}
                                                    errorText={
                                                        pcrData?.otherInjuryLocationError && (
                                                            <div className="slds-form-element__help selectionError df">
                                                                {pcrData?.otherInjuryLocationError && <>{pcrData?.otherInjuryLocationError}</>}
                                                            </div>
                                                        )
                                                    }
                                                />
                                            </div>
                                        )}
                                    </label>
                                </div>

                                {/* Mechanisms */}
                                <div style={{ width: "250px" }}>
                                    <label className="slds-form-element__label" htmlFor="mechanismTypes">
                                        Mechanisms
                                        <div className="width-field">
                                            <PCRComboBox
                                                id="mechanism-combo-box"
                                                label=""
                                                options={mechanismOptions}
                                                value={selectedMechanism}
                                                placeholder={t("Select")}
                                                onChange={handleMechanismChange}
                                                onSelect={(val) => handleMechanismChange(val.mechanism)}
                                                required={true}
                                                selection={selectedMechanism ? [{ id: selectedMechanism, label: selectedMechanism }] : []}
                                                name="mechanism"
                                                errorText={
                                                    pcrData?.mechanismError && (
                                                        <div className="slds-form-element__help selectionError df">
                                                            {pcrData?.mechanismError && <>{pcrData?.mechanismError}</>}
                                                        </div>
                                                    )
                                                }
                                            />
                                        </div>
                                        {selectedMechanism === "Fall" && (
                                            <div className="reflexive-container">
                                                <Input
                                                    id="fall-height"
                                                    placeholder="m"
                                                    value={fallHeight}
                                                    name="fallHeight"
                                                    type="number"
                                                    onChange={handleFallHeightChange}
                                                    onBlur={e => {
                                                        if (e.target.value < 0) {
                                                            setPCRData({ ...pcrData, ...{ fallHeightError: "Height must be >= 0 " } })
                                                        }
                                                        else {
                                                            setPCRData({ ...pcrData, ...{ fallHeightError: "" } });
                                                        }
                                                    }}
                                                    errorText={pcrData.fallHeightError && (
                                                        <div className="slds-form-element__help selectionError df">
                                                            {pcrData.fallHeightError}
                                                        </div>
                                                    )}
                                                    className="reflexive-field"
                                                />
                                                <Input
                                                    id="fall-surface"
                                                    placeholder="Landing surface"
                                                    name="fallSurface"
                                                    value={fallSurface}
                                                    onChange={handleFallSurfaceChange}
                                                    className="full-width"
                                                />
                                            </div>
                                        )}
                                        {selectedMechanism === "Down Stairs" && (
                                            <div className="reflexive-container">
                                                <Input
                                                    id="stairs-steps"
                                                    placeholder="#"
                                                    name="stairsSteps"
                                                    value={stairsSteps}
                                                    type="number"
                                                    onChange={handleStairsStepsChange}
                                                    onBlur={e => {
                                                        if (e.target.value < 0) {
                                                            setPCRData({ ...pcrData, ...{ stairsStepsError: t("invalidFormat") } })
                                                        }
                                                        else {
                                                            setPCRData({ ...pcrData, ...{ stairsStepsError: "" } });
                                                        }
                                                    }}
                                                    errorText={pcrData.stairsStepsError && (
                                                        <div className="slds-form-element__help selectionError df">
                                                            {pcrData.stairsStepsError}
                                                        </div>
                                                    )}
                                                    className="reflexive-field"
                                                />
                                                <Input
                                                    id="stairs-surface"
                                                    placeholder="Landing surface"
                                                    value={stairsSurface}
                                                    onChange={handleStairsSurfaceChange}
                                                    className="full-width"
                                                />
                                            </div>
                                        )}
                                    </label>
                                </div>

                                {/* Burns Dropdown */}
                                <div style={{ width: "250px" }}>
                                    <label className="slds-form-element__label" htmlFor="burnss">
                                        {
                                            (pcrData?.assesment?.protocolGuideline &&
                                                ["1055", "1060", "1065"
                                                ].some(value => pcrData?.assesment?.protocolGuideline.includes(value))) && <span style={{ color: "red" }}>*</span>
                                        }&nbsp;Burns
                                        <Tooltip
                                            isOpen={isTooltipOpen}
                                            onRequestClose={closeTooltip}
                                            content={
                                                <div ref={tooltipRef} style={{ position: "relative" }}>
                                                    <Button
                                                        assistiveText={{ icon: "close" }}
                                                        iconCategory="utility"
                                                        iconName="close"
                                                        iconSize="small"
                                                        variant="icon"
                                                        onClick={closeTooltip}
                                                        className="tooltip-close-button"
                                                    />
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/Burns_Reference_Guide.png`}
                                                        alt="Burns Reference Guide"
                                                        className="tooltip-image"
                                                    />
                                                </div>
                                            }
                                            align="top"
                                        >
                                            <Button
                                                assistiveText={{ icon: "info" }}
                                                iconCategory="utility"
                                                iconName="info"
                                                iconSize="small"
                                                variant="icon"
                                                onClick={toggleTooltip}
                                                className="slds-m-left_x-small"
                                            />
                                        </Tooltip>
                                        <div className="width-field">
                                            <PCRComboBox
                                                id="burn-type-combo-box"
                                                label=""
                                                options={burnOptions}
                                                value={selectedBurn}
                                                placeholder={t("Select")}
                                                onChange={handleBurnSelection}
                                                onSelect={(val) => handleBurnSelection(val.burns)}
                                                required={(pcrData?.assesment?.protocolGuideline &&
                                                    ["1055", "1060", "1065"
                                                    ].some(value => pcrData?.assesment?.protocolGuideline.includes(value)))}
                                                selection={selectedBurn ? [{ id: selectedBurn, label: selectedBurn }] : []}
                                                name="burns"
                                                errorText={
                                                    pcrData?.burnsError && (
                                                        <div className="slds-form-element__help selectionError df">
                                                            {pcrData?.burnsError && <>{pcrData?.burnsError}</>}
                                                        </div>
                                                    )
                                                }
                                            />
                                        </div>
                                        {["Liquid", "Chemicals", "Other"].includes(selectedBurn) && (
                                            <Input
                                                id="other-burn-detail"
                                                placeholder={t("Please specify")}
                                                value={otherBurnDetail}
                                                className="full-width-burn"
                                                onChange={handleOtherBurnDetailChange}
                                                required
                                                name="otherBurnDetail"

                                                onBlur={(e) => {
                                                    if (!e.target.value) {
                                                        setPCRData({ ...pcrData, ...{ otherBurnDetailError: t('inputIsRequiredError') } })
                                                    }
                                                    else {
                                                        setPCRData({ ...pcrData, ...{ otherBurnDetailError: "" } })
                                                    }
                                                }}

                                                errorText={
                                                    pcrData?.otherBurnDetailError && (
                                                        <div className="slds-form-element__help selectionError df">
                                                            {pcrData?.otherBurnDetailError && <>{pcrData?.otherBurnDetailError}</>}
                                                        </div>
                                                    )
                                                }
                                            />
                                        )}
                                    </label>
                                </div>


                                {/* Other Type of Injury */}
                                <div style={{ width: "250px" }}>
                                    <label className="slds-form-element__label" htmlFor="otherInjuryType">
                                        Other type of injury
                                        <Input
                                            id="other-injury-type"
                                            placeholder="Please specify"
                                            value={otherInjuryType}
                                            className="width-field"
                                            onChange={(e) => setOtherInjuryType(e.target.value)}
                                            onBlur={(e) => {
                                                if (!e.target.value) {
                                                    setPCRData({ ...pcrData, ...{ otherInjuryTypeError: t('inputIsRequiredError') } })
                                                }
                                                else {
                                                    setPCRData({ ...pcrData, ...{ otherInjuryTypeError: "" } })
                                                }
                                            }}
                                            required
                                            errorText={
                                                pcrData?.otherInjuryTypeError && (
                                                    <div className="slds-form-element__help selectionError df">
                                                        {pcrData?.otherInjuryTypeError && <>{pcrData?.otherInjuryTypeError}</>}
                                                    </div>
                                                )
                                            }
                                        />
                                    </label>
                                </div>

                            </div>
                        </div>

                        {/* Additional notes and findings */}
                        <div className="df">
                            <label className="slds-form-element__label" htmlFor="additionalNotes" style={{width: '100%'}}>
                                Additional notes and findings
                                <div className="additional-notes-container">
                                    <Textarea
                                        id="additional-notes"
                                        className="assesment_text_area_cls_custom"
                                        value={additionalNotes}
                                        onChange={(e) => {
                                            setAdditionalNotes(e.target.value);
                                            updatePCRContext({ additionalNotes: e.target.value });
                                        }}
                                        onBlur={(e) => {
                                            if (!e.target.value) {
                                                setPCRData({ ...pcrData, ...{ additionalNotesError: t('inputIsRequiredError') } })
                                            }
                                            else {
                                                setPCRData({ ...pcrData, ...{ additionalNotesError: "" } })
                                            }
                                        }}
                                        required={false}
                                        placeholder="Enter additional notes"
                                        errorText={
                                            pcrData?.additionalNotesError && (
                                                <div className="slds-form-element__help selectionError df">
                                                    {pcrData?.additionalNotesError && <>{pcrData?.additionalNotesError}</>}
                                                </div>
                                            )
                                        }
                                    />
                                   
                                </div>
                            </label>
                        </div>
                        <div className="df" style={{float: 'right'}}>
                             {/* ID# and Initials fields */}
                                    <div className="df" style={{marginTop: '-15px', marginright: '7px'}}>
                                        <Input
                                            id="assessment-id"
                                            placeholder="ID#"
                                            value={assessmentId}
                                            className="id-container"
                                            onChange={(e) => {
                                                setAssessmentId(e.target.value);
                                                updatePCRContext({ assessmentId: e.target.value });
                                            }}
                                            onBlur={(e) => {
                                                if (!e.target.value) {
                                                    setPCRData({ ...pcrData, ...{ assessmentIdError: t('inputIsRequiredError') } })
                                                }
                                                else {
                                                    setPCRData({ ...pcrData, ...{ assessmentIdError: "" } })
                                                }
                                            }}
                                            errorText={
                                                pcrData?.assessmentIdError && (
                                                    <div className="slds-form-element__help selectionError df">
                                                        {pcrData?.assessmentIdError && <>{pcrData?.assessmentIdError}</>}
                                                    </div>
                                                )
                                            }
                                        />
                                        <Input
                                            id="assessment-initials"
                                            placeholder="Initials"
                                            value={assessmentInitials}
                                            className="initials-container"
                                            name="assessmentInitials"
                                            onChange={(e) => {
                                                setAssessmentInitials(e.target.value);
                                                updatePCRContext({ assessmentInitials: e.target.value });
                                            }}
                                            onBlur={(e) => {
                                               handleIntialsBlur(e)
                                            }}
                                            errorText={
                                                pcrData?.assessmentInitialsError && (
                                                    <div className="slds-form-element__help selectionError df">
                                                        {pcrData?.assessmentInitialsError && <>{pcrData?.assessmentInitialsError}</>}
                                                    </div>
                                                )
                                            }
                                            pattern="[A-Za-zÀ-ÿ]*"
                                        />
                                    </div>
                        </div>
                    </>
                )}

                <div className="df h-4half-r" style={{marginTop: '2rem'}}>
                    {/* Splinting and Immobilization */}
                    <label className="slds-form-element__label" htmlFor="splintingOptions">
                        Splinting & immobilization
                        <Button
                            assistiveText={{ icon: "info" }}
                            iconCategory="utility"
                            iconName="info"
                            iconSize="medium"
                            variant="icon"
                            onClick={handleSCC}
                        />
                        <div className="align-down">
                            {selectedSplintingOptions.map((option) => (
                                <div key={option.id} style={{ display: "flex", alignItems: "center", marginBottom: "0.5rem" }}>
                                    <Checkbox
                                        assistiveText={{ label: option.label }}
                                        id={`checkbox-splinting-${option.id}`}
                                        labels={{ label: option.label }}
                                        checked={option.value}
                                        onChange={(e) => handleSplintingSelection(option.id, e.target.checked)}
                                    />
                                    {option.id === "other" && option.value && (
                                        <Input
                                            id="splinting-other"
                                            placeholder="Please specify"
                                            value={otherSplinting}
                                            onChange={handleOtherSplintingChange}
                                            onBlur={(e) => {
                                                if (!e.target.value) {
                                                    setPCRData({ ...pcrData, ...{ otherSplintingError: t('inputIsRequiredError') } })
                                                }
                                                else {
                                                    setPCRData({ ...pcrData, ...{ otherSplintingError: "" } })
                                                }
                                            }}
                                            required
                                            errorText={
                                                pcrData?.otherSplintingError && (
                                                    <div className="slds-form-element__help selectionError df">
                                                        {pcrData?.otherSplintingError && <>{pcrData?.otherSplintingError}</>}
                                                    </div>
                                                )
                                            }
                                            style={{ marginLeft: "1rem", width: "200px" }}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    </label>

                    {/* Bleeding */}
                    <label className="slds-form-element__label" htmlFor="bleedingOptions">
                        Bleeding
                        <div className="align-down">
                            {selectedBleedingOptions.map((option) => (
                                <div key={option.id} style={{ display: "flex", alignItems: "center", marginBottom: "0.5rem" }}>
                                    <Checkbox
                                        assistiveText={{ label: option.label }}
                                        id={`checkbox-bleeding-${option.id}`}
                                        labels={{ label: option.label }}
                                        checked={option.value}
                                        onChange={(e) => handleBleedingSelection(option.id, e.target.checked)}
                                    />
                                    {option.id === "tourniquet" && option.value && (
                                        <Input
                                            id="bleeding-tourniquet-time"
                                            placeholder="HH:MM"
                                            value={tourniquetTime}
                                            onChange={handleTourniquetTimeChange}
                                            onBlur={(e) => {
                                                if (!/^([01][0-9]|2[0-3]):[0-5][0-9]$/.test(tourniquetTime)) {
                                                    setPCRData({ ...pcrData, ...{ tourniquetTimeError: t("invalidFormat") } })
                                                }
                                                else {
                                                    setPCRData({ ...pcrData, ...{ tourniquetTimeError: "" } });
                                                }
                                            }}
                                            pattern="([01][0-9]|2[0-3]):[0-5][0-9]"  // 24-hour clock validation
                                            errorText={
                                                pcrData.tourniquetTimeError && (
                                                    <div className="slds-form-element__help selectionError df">
                                                        {pcrData?.tourniquetTimeError}
                                                    </div>
                                                )
                                            }
                                            style={{ marginLeft: "1rem", width: "100px" }}
                                        />
                                    )}
                                    {option.id === "other" && option.value && (
                                        <Input
                                            id="bleeding-other"
                                            placeholder="Please specify"
                                            value={otherBleeding}
                                            onChange={handleOtherBleedingChange}
                                            onBlur={(e) => {
                                                if (!e.target.value) {
                                                    setPCRData({ ...pcrData, ...{ otherBleedingError: t('inputIsRequiredError') } })
                                                }
                                                else {
                                                    setPCRData({ ...pcrData, ...{ otherBleedingError: "" } });
                                                }
                                            }}
                                            required
                                            errorText={
                                                pcrData?.otherBleedingError && (
                                                    <div className="slds-form-element__help selectionError df">
                                                        {pcrData?.otherBleedingError && <>{pcrData?.otherBleedingError}</>}
                                                    </div>
                                                )
                                            }
                                            style={{ marginLeft: "1rem", width: "200px" }}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    </label>
                </div>

            </div>

            {/* Validate button */}
            {/* <div className="df transfer-validate-btn-assesment">
                <Button variant="brand" onClick={handleValidate}>
                    {t("validate")}1
                </Button>
            </div> */}
        </>
    );
};